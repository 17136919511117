<template>
  <div class="festivalis">
    <header class="festivalis__header header">
      <div class="header__logo" @click="$emit('openHomeFromHeader')">
        <img src="@/assets/images/logo-2.svg" alt="Logo" class="notmobile" />
		<img src="@/assets/images/logo-white.svg" alt="Logo" class="mobile" />
      </div>

      <button
        type="button"
        class="header__burger burger-btn"
        @click="$emit('OpenBurgerFromHome')"
      >
        <svg
          width="24"
          height="18"
          viewBox="0 0 24 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="2.25" rx="1.125" fill="#00404E" />
          <rect y="7.5" width="24" height="2.25" rx="1.125" fill="#00404E" />
          <rect y="15" width="24" height="2.25" rx="1.125" fill="#00404E" />
        </svg>
      </button>
    </header>
    <title-line :title="festivalisData.title" class="festivalis__titleLine" />
    <div class="container">
      <div class="festivalis__content">
        <div class="festivalis__post">
          <div class="festivalis__headline" >
          Festivalis „Keliaujanti vėtrungė: tikėjimas, legendos ir knygų milžinai“
          </div>
          <div class="festivalis__text" >
          <p class="festivalis__title">APIE FESTIVALĮ</p>
          <p>Kiekviena vietovė turi savo išskirtinių bruožų. Etnografinio Mažosios Lietuvos regiono savitumai itin ryškūs: jūra, marios, potvyniai, vėjai. Žmogaus tapatybei turi įtakos vietos tapatybės pojūtis. Kiekvienas šiandien čia gyvenantis tapatinasi su jūros, marių artumu. Laikui bėgant keičiasi vietos gyventojai, jų įpročiai ir gyvenamoji aplinka. Kinta ir tapatybės sąvoka. Šiandien amatas tapo ne tik pragyvenimo šaltiniu, bet  ir meninės saviraiškos priemone, bažnyčioje šiandien vyksta ne tik pamaldos, bet ir kultūriniai renginiai. Šiandien gimę žmonės kuria savo vertybes, formuoja laikmečio tapatybę. Tačiau visada išlieka krašto istorijos ženklai, todėl šiandien vietos tapatumas gali būti atskleistas ir per kultūrinius bei gamtos ženklus.</p>
          </div>
          <div class="festivalis__image">
            <img src="@/assets/images/fest1.jpg" alt="" />
          </div>
          <div class="festivalis__text" >
          <p>Festivalis „Keliaujanti vėtrungė: tikėjimas, legendos ir knygų milžinai“ siekia šiuolaikinio meno išraiškos priemonėmis skleisti žinią apie penktąjį etnografinį Lietuvos regioną – Mažąją Lietuvą. Tai Lietuvos kraštas, kuris unikalus savo tarme, švelnia gamta, marinistiniais garsais, didžiais veikėjais ir lėtu gyvenimo tempu. Festivalyje išvysite profesionalaus meno pasirodymus, kurie papasakos apie magišką Lietuvos kraštą – Mažąją Lietuvą.</p> 
          </div>
          <br clear="all" />
          <div class="festivalis__image_R">
            <img src="@/assets/images/fest2.png" alt="" />
          </div>
          <div class="festivalis__text" >
          <p class="festivalis__title">BITĖNAI | MARTYNO JANKAUS MUZIEJUS | BIRŽELIO 21 | 19:00</p>
          <p>Pirmasis festivalio renginys vyks birželio 21 dieną šalia Mažosios Lietuvos panteono – Martyno Jankaus muziejuje. Skambant Klaipėdos kamerinio orkestro (meno vadovas Mindaugas Bačkus) muzikai, renginio metu bus skaitomi dramaturgo Mindaugo Valiuko tekstai. Dalyviai išgirs šio krašto istoriją, pajaus iškiliausių, čia amžino poilsio atgulusių, Mažosios Lietuvos knygų milžinų: Vydūno, Martyno Jankaus bei Kristijono Donelaičio dvasią ir svarbą ne tik Mažosios Lietuvos regionui, bet ir visai Lietuvai.</p>
          </div>
          <br clear="all" />          
          <div class="festivalis__image">
            <img src="@/assets/images/fest3.jpg" alt="" />
          </div>
          <div class="festivalis__text" >
          <p class="festivalis__title">NIDA | PARNIDŽIO KOPOS SAULĖS LAIKRODIS | LIEPOS 13 | 22:00</p>
          <p>Antrasis festivalio renginys vyks liepos 13-ąją, Nidoje, prie Parnidžio kopos saulės laikrodžio. Klausantis Lauritos Peleniūtės su grupe muzikinės programos ir saulėlydžio prieblandoje „gaudant“ gintarą per mistifikuotas šviesos projekcijas, festivalio dalyviai pateks į vietovę, kurioje gyvena legendas šnabždantys gintarai.</p>
          </div>
          <br clear="all" />          
          <div class="festivalis__image_R">
            <img src="@/assets/images/fest4.jpg" alt="" />
          </div>
          <div class="festivalis__text" >
          <p class="festivalis__title">VENTĖ | VENTĖS RAGO ŠVYTURYS | RUGPJŪČIO 16 | 21:00</p>
          <p>Paskutinis festivalio renginys vyks švyturių dieną, rugpjūčio 16, prie Ventės Rago švyturio. Čia stebint šokio trupės „Nuepiko“ modernaus šokio programą, klausant užburiančios šiuolaikinės ir folkloro motyvais apipintos muzikos, festivalio žiūrovai susipažins su seniausiu Mažojoje Lietuvoje esančio švyturio dvasia ir tikėjimu, kuris nušviesdavo žvejams saugų kelią į namus.</p>
          </div>
          <br clear="all" />
          <div class="festivalis__text" style="max-width: 520px; margin: 0 auto;" >
          <p class="festivalis__title">FESTIVALIO KŪRYBINĖ GRUPĖ:</p>
          <ul style="list-style: none; padding: 0px;">
          <li />Projekto vadovė Viktorija Binkulienė
          <li />Režisierius Marius Tumšys
          <li />Kompozitorius Marius Salynas
          <li />Dramaturgas Mindaugas Valiukas
          <li />Klaipėdos kamerinis orkestras, meno vadovas Mindaugas Bačkus
          <li />Aktoriai Jonas Baranauskas, Edvardas Brazys
          <li />Laurita Peleniūtė su grupe
          <li />Šokio trupė „Nuepiko“, choreografas Marius Pinigis
          <li />Vaizdo režisierius / operatorius Mindaugas Praninskas
          </ul>
          </div>

          <div class="festivalis__partners">
          
          <div class="festivalis__partners__group">
			<div class="festivalis__partners__group__title">ORGANIZATORIAI:</div>
			<div class="festivalis__partners__logo">
				<img src="@/assets/images/festivalis/Silutes kulturos centras baltas logo.png" alt="" /> 
			</div>
			<div class="festivalis__partners__logo">
				<img src="@/assets/images/festivalis/Vetrungiu kelias logo baltas.png" alt="" /> 
			</div>
          </div>

          <div class="festivalis__partners__group" style="margin-bottom: 20px;">
			<div class="festivalis__partners__group__title">PROJEKTĄ FINANSUOJA:</div>
			<div class="festivalis__partners__logo">
				<img src="@/assets/images/festivalis/Lietuvos kultūros taryba.png" alt="" /> 
			</div>
			<div class="festivalis__partners__logo">
				<img src="@/assets/images/festivalis/Silutes rajono savivaldybe.png" alt="" /> 
			</div>
          </div>

          <div class="festivalis__partners__group">
			<div class="festivalis__partners__group__title">INFORMACINIS PARTNERIS:</div>
			<div class="festivalis__partners__logo">
				<img src="@/assets/images/festivalis/LRT.png" alt="" /> 
			</div>
          </div>

          <div class="festivalis__partners__group partneriai">
			<div class="festivalis__partners__group__title" style="padding-left:25px;">PARTNERIAI:</div>
			<div class="festivalis__partners__logo partn">
				<img src="@/assets/images/festivalis/AGILA.png" alt="" /> 
			</div>
			<div class="festivalis__partners__logo partn">
				<img src="@/assets/images/festivalis/Dvaro_kavos.png" alt="" /> 
			</div>
			<div class="festivalis__partners__logo partn">
				<img src="@/assets/images/festivalis/Martyno Jankaus muziejus.png" alt="" /> 
			</div>
			<div class="festivalis__partners__logo partn">
				<img src="@/assets/images/festivalis/Mizgirių gintaro muziejus.png" alt="" /> 
			</div>
			<div class="festivalis__partners__logo partn">
				<img src="@/assets/images/festivalis/Vente.png" alt="" /> 
			</div>
			<div class="festivalis__partners__logo partn">
				<img src="@/assets/images/festivalis/Uostadvaris.png" alt="" /> 
			</div>
			<div class="festivalis__partners__logo partn">
				<img src="@/assets/images/festivalis/NERINGOS-MUZIEJAI_Logotypes_LT-ENG-DE.2-1_baltas.png" alt="" /> 
			</div>
			<div class="festivalis__partners__logo partn">
				<img src="@/assets/images/festivalis/ZooMuz_baltas.png" alt="" /> 
			</div>
          </div>
		</div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleLine from './titleLine.vue'
export default {
  name: 'vaneRoad',
  components: {
    titleLine,
  },
  data() {
    return {
      festivalisData: {
        title: 'FESTIVALIS',
      },
    }
  },
  computed: {},
}
</script>

<style lang="scss">
.festivalis {
  &__header {
    position: static;
    transform: none;
    .header__logo {
      width: 62px;
      @media (max-width: 575.98px) {
        width: 54px;
      }
    }
  }



  &__partners__group__title {
  
    font-size: 14px;
    margin-bottom: 20px;
	text-align: left;
	font-weight: 900;
  }

  &__partners {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
	@media (max-width: 935px) {
        flex-direction: column;
      }
	@media (max-width: 575.98px) {
        align-items: center;
      }	  
  }
  
  
  &__partners__logo {
  width: 200px;
  float: left;
  @media (max-width: 575.98px) {
        float: unset;
      }
  }

  .partn {
	width: 120px;
  }

  &__partners__group {
	float: left;
	min-width: 200px;
}

&__partners .partneriai {
  @media (max-width: 575.98px) {
        max-width: 250px;
      }
}

.partneriai &__partners__logo {
  @media (max-width: 575.98px) {
        float: left;
      }
}
  
  
  &__headline {
  
  width: 100%;
  padding: 20px 25px;
  color: #ffffff;
  background: #2a606b;
  font-size: 28px;
  text-align: center;
  margin-bottom: 50px;
  
  
  }
  
  &__partners {
  
  width: 100%;
  padding: 20px 25px;
  color: #ffffff;
  background: #2a606b;
  font-size: 28px;
  text-align: center;
  margin-top: 50px;
  
  
  }  
  
  &__content {
    padding: 55px 0 0;
    @media (max-width: 575.98px) {
      padding: 15px 0 35px;
    }
  }

  &__post {
    min-height: 200px;
    @media (max-width: 575.98px) {
      flex-direction: column;
      display: flex;
      row-gap: 15px;
    }
  }

  &__image, &__image_R {
    float: left;
    flex: none;
    margin-right: 20px;
    margin-bottom: 20px;
    height: 300px;
    img {
      height: 100%;
    }
    @media (max-width: 991.98px) {
      height: 200px;
    }
    @media (max-width: 575.98px) {
      width: stretch;
      margin-right: 0px;
      float: unset;
      margin-bottom: unset;
      width: 100%;
      height: unset;
    }
  }

	&__image_R {
		float: right;
		margin-left: 20px;
        @media (max-width: 575.98px) {
        margin-left: 0px;
        }


	}

  &__text {
    line-height: 1.75;
    color: #383838;
  }
  &__video {
    margin-bottom: 50px;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    @media (max-width: 575.98px) {
      margin-bottom: 20px;
    }
    iframe,
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__descr {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 30px;
    @media (max-width: 575.98px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  &__row {
    display: flex;
    column-gap: 130px;
    @media (max-width: 767.98px) {
      flex-direction: column;
      row-gap: 24px;
      align-items: flex-start;
    }
  }
  &__col {
    max-width: 446px;
  }
  &__sub-descr {
    font-size: 20px;
    margin-bottom: 24px;
    @media (max-width: 575.98px) {
      font-size: 14px;
      margin-bottom: 12px;
    }
  }
  
  &__text > p {
  margin-bottom: 15px;
  }  
  
  &__title {
		font-weight: 900;
  }

  &__btn {
    flex: none;
    color: #ffffff;
    padding: 10px 27px;
    border-radius: 30px;
    background: #db3831;
    width: fit-content;
    @media (max-width: 575.98px) {
    }
  }
}
</style>
