<template>
  <div class="pamatyk-pazink">
    <header class="pamatyk-pazink__header header">
      <div class="header__logo" @click="$emit('openHomeFromHeader')">
        <img src="@/assets/images/logo-2.svg" alt="Logo" class="notmobile" />
		<img src="@/assets/images/logo-white.svg" alt="Logo" class="mobile" />
      </div>

      <button
        type="button"
        class="header__burger burger-btn"
        @click="$emit('OpenBurgerFromHome')"
      >
        <svg
          width="24"
          height="18"
          viewBox="0 0 24 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="2.25" rx="1.125" fill="#00404E" />
          <rect y="7.5" width="24" height="2.25" rx="1.125" fill="#00404E" />
          <rect y="15" width="24" height="2.25" rx="1.125" fill="#00404E" />
        </svg>
      </button>
    </header>
    <title-line :title="pamatykPazinkData.title" />
    <div class="container">
	
	
      <div class="pamatyk-pazink__content">
		<p class="vane-road__descr">Ekskursijos</p>
        <div class="pamatyk-pazink__post">
		<div v-for="item in pamatykPazinkData.ekskursijos" :key="item" class="pamatyk-pazink__item">
		<div class="pamatyk-pazink__title">{{ item.title }}</div>
		<p class="pamatyk-pazink__text">
		{{ item.text }}
		</p>
		<p class="pamatyk-pazink__text">
		{{ item.adresas }}
		</p>		
         <a target="_blank" :href="item.info">
         <div class="pamatyk-pazink__btn">
            Daugiau informacijos
         </div></a>
        </div>
        </div>
		
		<p class="vane-road__descr">Ekspozicijos</p>
        <div class="pamatyk-pazink__post">
		<div v-for="item in pamatykPazinkData.ekspozicijos" :key="item" class="pamatyk-pazink__item">
		<div class="pamatyk-pazink__title">{{ item.title }}</div>
		<p class="pamatyk-pazink__text">
		{{ item.text }}
		</p>
		<p class="pamatyk-pazink__text">
		{{ item.adresas }}
		</p>		
         <a target="_blank" :href="item.info">
         <div class="pamatyk-pazink__btn">
            Daugiau informacijos
         </div></a>
        </div>
        </div>
        
		<p class="vane-road__descr">Edukacinės programos ir kiti renginiai</p>
		<div class="pamatyk-pazink__post">
		<div v-for="item in pamatykPazinkData.programos" :key="item" class="pamatyk-pazink__item">
		<div class="pamatyk-pazink__title">{{ item.title }}</div>
		<p class="pamatyk-pazink__text">
		{{ item.text }}
		</p>
		<p class="pamatyk-pazink__text">
		{{ item.adresas }}
		</p>		
         <a target="_blank" :href="item.info">
         <div class="pamatyk-pazink__btn">
            Daugiau informacijos
         </div></a>
        </div>
        </div>
        
		
		<p class="vane-road__descr">Kultūrinės-edukacinės ekskursijos</p>
		
        <div class="pamatyk-pazink__post">
		<div v-for="item in pamatykPazinkData.edukacijos" :key="item" class="pamatyk-pazink__item">
		<div class="pamatyk-pazink__title">{{ item.title }}</div>
		<p class="pamatyk-pazink__text">
		{{ item.text }}
		</p>
		<p class="pamatyk-pazink__text">
		{{ item.adresas }}
		</p>		
         <a target="_blank" :href="item.info">
         <div class="pamatyk-pazink__btn">
            Daugiau informacijos
         </div></a>
        </div>
        </div>

		</div>
      </div>
    </div> 
	
	
    
  
</template>

<script>
import titleLine from './titleLine.vue'
export default {
  name: 'pamatykPazink',
  components: {
    titleLine,
  },
  data() {
    return {
      pamatykPazinkData: {
        title: 'Pamatyk ir pažink',
		ekskursijos: [
		{  
		title: '„E. V. Berbomas ir Kuršių marių vėtrungės“',
		text: 'Tai pažintis su Klaipėdos miesto burmistru, 1841 m. Vokietijos karališkajai vyriausybei paskyrus jį vyriausiuoju visų Kuršių marių ir į ją įtekančių upelių žūklės prievaizdu, didžiąją gyvenimo dalį praleido šalia Kuršių marių buvusiame Muižės dvare (dabar Kintų seniūnija, Šilutės r.). Jo dėka, turime Lietuvos etnokultūros paveldo unikumu tapusius pamario krašto žvejų kaimų ženklus – Kuršmarių vėtrunges, neturinčias analogų visoje Europoje.',
		adresas: '(Kintų Vydūno kultūros centras, Kintai, Šilutės r.)',
		info: 'https://www.vydunocentras.lt/'
		}, 
		{ 
		title: 'Ekskursija po istorinį Juodkrantės vilų kvartalą',
		text: 'Ekskursijoje susipažįstama su senosios Juodkrantės vilų ir viešbučių gyvavimu iki II-ojo pasaulinio karo ir palyginti su išlikusiais iki mūsų dienų rekonstruotais pastatais. Svarbiausios permainos Kuršių nerijos kraštovaizdyje prasidėjo XIX a. pabaigoje – XX a. pradžioje. Įmantrios, neva šveicariško ir gotikinio stiliaus vilos, viešbučiai ir svečių namai daugiausia buvo statomi Juodkrantėje, o 1881 m. čia susikūrė ir pirmasis Kurorto komitetas.',
		adresas: '(Liudviko Rėzos kultūros centras, Juodkrantė, Neringa)',
		info: 'https://www.lrezoskc.lt/'
		},
		{ 
		title: 'Ekskursija Kuršių nerijos istorijos muziejuje',
		text: 'Ekspozicijoje pristatomi Kuršių nerijos gyventojų verslai (varnų gaudymas maistui, žvejybos įrankiai, valčių maketai), tradicijos. Lankytojų dėmesį itin patraukia specifinį žūklės metodą – bumbinimą – demonstruojantis manekenas. Vertinga ekspozicijos dalis – unikalūs akmens amžiaus Nidos archeologinių kasinėjimų 1974–1978 m. metu surasti radiniai.',
		adresas: '(Neringos muziejai, Nida, Neringa)',
		info: 'https://www.neringosmuziejai.lt/'
		},
		{ 
		title: 'Ekskursija reisine „Dreverna“',
		text: 'Kelionė Kuršių mariomis įspūdingu, statytu pagal autentiškas tradicijas, senoviniu buriniu laivu – reisine „Dreverna“ ir gidu.',
		adresas: '(Dreverna, Klaipėdos r.)',
		info: 'https://www.klaipedosrajonas.lt/'
		},
		{ 
		title: 'Ekskursiją „Šilutės sekretai“',
		text: '„Šilutės sekretai“ - tai unikalus projektas Lietuvoje ir Europoje, kur miesto istorija atskleidžiama mažosios architektūros elementais. Tai uždengti stiklu ir įmontuoti šaligatvyje unikalūs kambarėliai, kuriuose atkurtas praeities gyvenimas, vykęs istoriniuose Šilutės namuose XIX a. pabaigoje – XX a. pradžioje.',
		adresas: '(Šilutės turizmo ir informacijos centras, Šilutė)',
		info: 'https://siluteinfo.lt/'
		},
		{ 
		title: 'Ekskursija-žaidimas „Vėtrungių keliu“',
		text: 'Maršrutas: Klaipėda-Dreverna-Priekulė-Aukštumala-Šilutė-Klaipėda. Tai ne tik ekskursija po kultūros paveldo objektus, bet ir įtraukiantis žaidimas, su iš anksto paruoštais albumais ir kortelių rinkiniais, kuriuos kelionės metu reikia užpildyti.',
		adresas: '(UAB „Pabaltijo turas“, Klaipėda)',
		info: 'https://windbiketours.com/'
		},
		{ 
		title: 'Ekskursija-žaidimas „Vėtrungių keliu“',
		text: 'Klaipėda-Pagėgiai-Bitėnai-Vilkyškiai-Smalininkai-Klaipėda. Tai ne tik ekskursija po kultūros paveldo objektus, bet ir įtraukiantis žaidimas, su iš anksto paruoštais albumais ir kortelių rinkiniais, kuriuos kelionės metu reikia užpildyti.',
		adresas: '(UAB „Pabaltijo turas“, Klaipėda)',
		info: 'https://windbiketours.com/'
		},
		{ 
		title: 'Ekskursija-žaidimas „Vėtrungių keliu“',
		text: 'Klaipėda-Smiltynė-Juodkrantė-Nida-Klaipėda. Tai ne tik ekskursija po kultūros paveldo objektus, bet ir įtraukiantis žaidimas, su iš anksto paruoštais albumais ir kortelių rinkiniais, kuriuos kelionės metu reikia užpildyti.',
		adresas: '(UAB „Pabaltijo turas“, Klaipėda)',
		info: 'https://windbiketours.com/'
		},
		{ 
		title: 'Apžvalginė ekskursija pėsčiomis po Juodkrantę\nApžvalginė ekskursija pėsčiomis po Nidą\nEkskursija šeimai pėsčiomis „Raganų kalno pasakos“\nEkskursija po Kuršių neriją, naudojantis visuomeniniu transportu\nEkskursija po Kuršių neriją\nPažintinis žygis Juodkrantės sengirės takais ir promenadomis',
		text: '',
		adresas: '(Nidos kultūros ir turizmo informacijos centras „Agila“, Neringa) ',
		info: 'https://visitneringa.com/lt/'
		},
		{ 
		title: '„Dingusi karalystė“',
		text: 'Ekskursija su specialu dvisluoksniu žemėlapiu su iki šių dienų išlikusiais miesto objektais, menančiais Prūsijos karalystės gyvavimą Klaipėdos krašte.',
		adresas: '(Klaipėdos turizmo informacijos centras, Klaipėda)',
		info: 'https://klaipedatravel.lt/'
		},
		{ 
		title: '„Klaipėda: legendos, laivai ir skoniai“',
		text: 'Ekskursijos metu ne tik išgirsite Klaipėdos pilies vaiduoklio legendą, bet ir klausysitės jūrinių Klaipėdos istorijų. Skanausite šiam kraštui būdingą kulinarinį paveldą.',
		adresas: '(Klaipėdos turizmo informacijos centras, Klaipėda)',
		info: 'https://klaipedatravel.lt/'
		},
		{ 
		title: '„Mažosios Lietuvos patriotų takais“',
		text: 'Ekskursijos metu lankytojai supažindinami su Mažosios Lietuvos, Rambyno kalno atsiradimo istorija, pristatoma Rambyno RP lankytojų centro ekspozicija, aplankomos Bitėnų-Užbičių kapinaitės vadinamos Mažosios Lietuvos panteonu, iškilių Mažosios Lietuvos veikėjų amžinojo poilsio vieta.',
		adresas: '(ML saugomų teritorijų direkcijos Rambyno regioninio parko grupė, Bitėnai, Pagėgių sav.)',
		info: 'https://rambynoparkas.lrv.lt/'
		},
		{ 
		title: '„Rusnė - Mažosios Lietuvos atspindys“',
		text: 'Ekskursija po unikalų gamtos kampelį, didžiausią Lietuvos salą, kurią iš visų pusių supa vanduo. Ji įsikūrusi vienoje iš Nemuno deltų, ten kur kertasi Atmatos ir Skirvytės upės.',
		adresas: '(ML saugomų teritorijų direkcijos Nemuno deltos regioninis parkas, Rusnė, Šilutės r.)',
		info: 'http://www.nemunodelta.lt/'
		}
		],
		ekspozicijos: [
		{ 
		title: '„Etnografinė pajūrio žvejo sodyba“',
		adresas: '(Lietuvos jūrų muziejus, Klaipėda)',
		info: 'https://muziejus.lt/'
		},
		{ 
		title: '„H. Šojaus muziejaus etnografinė ekspozicija“',
		adresas: '(Šilutės H. Šojaus muziejus, Šilutė)',
		info: 'https://www.silutesmuziejus.lt/'
		},
		{ 
		title: '„Mažosios Lietuvos Jurbarko krašto kultūros centro ekspozicija“',
		adresas: '(Smalininkai, Jurbarko r. sav.)',
		info: 'https://www.mazosioslietuvoskc.eu/'
		},
		{ 
		title: '„Mažosios Lietuvos paveikslų sodas- galerija po atviru dangumi“',
		adresas: '(Bitėnai, Pagėgių sav.)',
		info: 'https://www.jankausmuziejus.lt/'
		},
		{ 
		title: '„Nidos žvejo etnografinė sodyba“',
		adresas: '(Nida, Neringa)',
		info: 'https://www.neringosmuziejai.lt/'
		},	
		{ 
		title: '„Pagėgių sav. Martyno Jankaus muziejaus  ekspozicija“',
		adresas: '(Bitėnai, Pagėgių sav.)',
		info: 'https://www.jankausmuziejus.lt/'
		},	
        { 
		title: '„Rambynas – kalnas – alkas“',
		adresas: '(ML saugomų teritorijų direkcijos Rambyno regioninio parko grupė, Bitėnai, Pagėgių sav.)',
		info: 'https://rambynoparkas.lrv.lt/'
		},	
        { 
		title: 'Vėtrungių ekspozicija, etnokultūrinė paroda „Kuršmarių vėtrungės – Vėlukai“',
		adresas: '(Kintų Vydūno kultūros centras, Kintai, Šilutės r.)',
		info: 'https://www.vydunocentras.lt/'
		},	
        { 
		title: '„Vėtrungių ekspozicija“',
		adresas: '(Kuršių marių krantinė, Nida, Neringa)',
		info: 'https://www.neringosmuziejai.lt/lt'
		}  
		],
		programos: [
		{ 
		title: '„Aš kultūra ir vėjas“',
		adresas: '(Šilutės kultūros ir pramogų centras, Šilutė)',
		info: 'https://www.silutekpc.lt/'
		},
		{ 
		title: '„Apie kurėną ir kitus pamario savitumus“',
		adresas: '(Gargždų krašto muziejus, Dreverna, Klaipėdos r.)',
		info: 'https://www.gargzdumuziejus.lt/'
		},
		{ 
		title: '„Delmonų edukacija“',
		adresas: '(Klaipėdos m. sav. Etnokultūros centras, Klaipėda)',
		info: 'https://www.etnocentras.lt/'
		},
		{ 
		title: '„Edukacinė programa „Kuršmarių vėtrungės – Vėlukai“',
		adresas: '(Kintų Vydūno kultūros centras, Kintai, Šilutės r.)',
		info: 'https://www.vydunocentras.lt/'
		},
		{ 
		title: 'Edukacinė veikla - žaidimas „Linksmai keliauk ir pažink Rambyno regioninį parką“',
		adresas: '(ML saugomų teritorijų direkcijos Rambyno regioninio parko grupė, Bitėnai, Pagėgių sav.)',
		info: 'https://rambynoparkas.lrv.lt/'
		},
		{ 
		title: '„Edukacija K. Banio sodyboje“',
		text: '200 metų senumo žvejo sodyboje lankytojai skanauja kulinarinio paveldo ir iš arčiau susipažįsta su žvejyba ir žvejo gyvenimu.',
		adresas: '(Rusnė, Šilutės r. sav.)',
		info: 'http://www.rusnescentras.lt/'
		},
		{ 
		title: '„Nuspalvink laimės žuvį“',
		adresas: '(Šilutės H. Šojaus muziejus, Šilutė)',
		info: 'https://www.silutesmuziejus.lt/'
		},
		{ 
		title: '„Patirk keramiką“',
		adresas: '(Pervalkos bendruomenės namai, Neringa)',
		info: 'https://visitneringa.com/lt/'
		},
		{ 
		title: '„Ritininė ar kodeksinė?“',
		text: 'Knygos formos raida nuo ritininės iki kodeksinės istoriniame kontekste.',
		adresas: '(Pagėgių sav. M. Jankaus muziejus, Bitėnai, Pagėgių sav.)',
		info: 'https://www.jankausmuziejus.lt/'
		},
		{ 
		title: '„Sukurk ir nuspalvink vėtrungėlę“',
		adresas: '(Kintų Vydūno kultūros centras, Kintai, Šilutės r.)',
		info: 'https://www.vydunocentras.lt/'
		},
		{ 
		title: '„Suprantu šišioniškių kalbą“',
		adresas: '(Gargždų krašto muziejus, Dreverna, Klaipėdos r.)',
		info: 'https://www.gargzdumuziejus.lt/'
		},
		{ 
		title: '„Vėtrungės uodega“',
		adresas: '(Neringos muziejai, Nida, Neringa)',
		info: 'https://www.neringosmuziejai.lt/'
		},
		{ 
		title: '„Vėtrungių edukacija“',
		adresas: '(Klaipėdos m. sav. Etnokultūros centras, Klaipėda)',
		info: 'https://www.etnocentras.lt/'
		},
		{ 
		title: '„Vėtrungės – magneto dažymas”',
		adresas: '(Šilutės turizmo ir informacijos centras, Šilutė)',
		info: 'https://siluteinfo.lt/'
		},
		],
		edukacijos: [
		{ 
		title: 'Edukacinė ekskursija “Mažame akvarelės taškelyje – didelis pasaulis”',
		text: 'Tai pasakojimas apie miniatiūros meistrės Lidijos Meškaitytės kūrybą  ir gyvenimą ir tai  formavusią  Mažosios Lietuvos  istoriją,  tradicijas.',
		adresas: '(Mažosios Lietuvos Jurbarko krašto kultūros centras, Smalininkai, Jurbarko sav.)',
		info: 'https://www.mazosioslietuvoskc.eu/'
		},
		{ 
		title: 'Edukacinė pramoginė programa „Žuvies kelias“',
		text: 'Lietuvos pamario gyvenvietėje, Drevernoje, gamta sukūrė palankias sąlygas gyvenimui ir žvejybos verslui. Čia susipažinsite su žvejybos Kuršių mariose istorija, tradicijomis, žvejų gyvenimo būdu ir laivadirbyste.',
		adresas: '(Etnografinė J. Gižo sodyba, Dreverna, Klaipėdos r.)',
		info: 'https://www.klaipedosrajonas.lt/'
		},
		{ 
		title: 'Folkloro festivalis „Pūsk, vėjuži!“ Juodkrantėje',
		text: 'Ši šventė vyksta rugpjūčio pabaigoje ir yra skirta pagerbti ir paminėti Kuršių nerijoje gimusio, lietuvių folkloristikos pradininko, tautosakos rinkėjo Martyno Liudviko Rėzos gimimo metų sukaktį. Paskutinis vasaros savaitgalis Juodkrantėje stebina išskirtinėmis dokumentinių filmų peržiūromis, paskaitomis, kostiumų demonstravimais bei Lietuvos ir užsienio folkloro kolektyvų pasirodymais.',
		adresas: '(Juodkrantė, Neringos sav.)',
		info: 'https://neringa.lt/'
		}
		],      
		},
    }
  },
  computed: {},
}


</script>

<style lang="scss">

.pamatyk-pazink {
  &__item {
     margin-bottom: 30px;
  }
  
  &__header {
    position: static;
    transform: none;
    .header__logo {
      width: 62px;
      @media (max-width: 575.98px) {
        width: 54px;
      }
    }
  }
  &__content {
    padding: 55px 0 85px;
    @media (max-width: 575.98px) {
      padding: 15px 0 35px;
    }
  }

  &__post {
    margin-bottom: 65px;
    @media (max-width: 575.98px) {
      flex-direction: column;
      display: flex;
      row-gap: 15px;
      margin-bottom: 30px;
    }
  }

  &__text {
    line-height: 1.75;
    color: #383838;
  }

  &__title {
    font-weight: bold;
	white-space: pre-wrap;
  }


  &__row {
    display: flex;
    align-items: center;
    column-gap: 40px;
    justify-content: space-between;
    @media (max-width: 767.98px) {
      flex-direction: column;
      row-gap: 15px;
      align-items: flex-start;
    }
  }

  &__descr {
    max-width: 720px;
    font-weight: 600;
    font-size: 36px;
    @media (max-width: 575.98px) {
      font-size: 20px;
    }
  }

  &__btn {
    flex: none;
    color: #ffffff;
    padding: 10px 27px;
    border-radius: 30px;
    background: #db3831;
    width: fit-content;
	margin-top: 10px;
    @media (max-width: 575.98px) {
    }
  }
}
</style>
