<template>
  <div
    v-click-outside="closeCard"
    class="mark"
    v-bind:style="{
      transform: 'scale(' + 1 / zoomvalue + ')',
    }"
	:ilguma=mark.ilguma
	:platuma=mark.platuma
    :id="'mark_'+mark.id"
    :data-region="mark.category_title"
  >
    <mark-btn :mark="mark" @click.native="showCard(mark)" />
    <transition>
      <mark-card
        v-if="cardOpen"
        :mark="mark"
        @addCardToSelected="addCardToSelected"
        @closeCard="closeCardBtn()"
        v-bind:style="{
          transform: 'scale(' + 1 + ')',
        }"
      />
    </transition>
  </div>
</template>

<script>
var addedMarks = [];

const startMapY = 56.15;
const endMapY = 54.879788;
const deffMapY = endMapY - startMapY;

const startMapX = 20.44;
const endMapX = 24.05;
const deffMapX = endMapX - startMapX;


import ClickOutside from 'vue-click-outside'
import MarkBtn from './mark-btn.vue'
import MarkCard from './mark-card.vue'

/*
function updateSpread(idd) {  
      
      var thisMark = document.getElementById("mark_"+idd);
      var mW = document.getElementsByClassName("map")[0].getBoundingClientRect().width / 100;
      var mH = document.getElementsByClassName("map")[0].getBoundingClientRect().height / 100;
      
      if (addedMarks.length > 0) {
        
        var curTop = thisMark.style.top;
        var curLeft = thisMark.style.left;
        
        var T = thisMark.getBoundingClientRect().top;
        var B = thisMark.getBoundingClientRect().bottom;
        var L = thisMark.getBoundingClientRect().left;
        var R = thisMark.getBoundingClientRect().right;
        
        var h = (T-B)/1.5;
        var w = (R-L)/1.5;
        
        addedMarks.filter(idas => idas != idd).forEach((idas) => {
            
            
          var markItem = document.getElementById("mark_"+idas); 
           var OT = markItem.getBoundingClientRect().top;
           var OB = markItem.getBoundingClientRect().bottom;
           var OL = markItem.getBoundingClientRect().left;
           var OR = markItem.getBoundingClientRect().right;
           
           //var OOx = OL + w/2;
           //var OOy = OT + h/2;
     
           var k1 = 1;
           
          if (!((OT > B) || (OB < T) || (OL > R)  || (OR < L))) {
           
           //overlap
            var vlength = Math.sqrt((OT - T)*(OT - T) + (OL - L)*(OL - L));
            
            if (Math.abs(OL - L) < Math.abs(OT - T)) {
                if (OT == T) {
                    
                    
                    k1 = (1/((OL - L)/vlength))*w;
                    curTop += k1*((OT - T)/vlength) / mH;
                    curLeft += k1*((OL - L)/vlength) / mW;
                    thisMark.style.top =  curTop + '%';
                    thisMark.style.left = curLeft + '%';
                } else {
                    
                    k1 = (1/((OT - T)/vlength))*h;
                    curTop += k1*((OT - T)/vlength) / mH;
                    curLeft += k1*((OL - L)/vlength) / mW;
                    thisMark.style.top =  curTop + '%';
                    thisMark.style.left = curLeft + '%';
                }
                
                
            } else {
                //console.log(this.mark.id + " tempt W" + " " +  L + " " + OL  + " " +  ((OL - L)/vlength));   
                    
                    k1 = (1/((OL - L)/vlength))*w;
                    
                    curTop += k1*((OT - T)/vlength) / mH;
                    curLeft += k1*((OL - L)/vlength) / mW;
                    thisMark.style.top =  curTop + '%';
                    thisMark.style.left = curLeft + '%';
            }
            
            
            
            thisMark.style.top += h/mH + '%';
            thisMark.style.left += w/mW + '%';
            
          }
        });

}
}
*/
export default {
  name: 'v-mark-item',
  components: {
    'mark-btn': MarkBtn,
    'mark-card': MarkCard,
  },
  data() {
    return {
      cardOpen: false,
      isOpen: false,
      calculated: this.calculated,

    }
  },
  props: {
    zoomvalue: null,
    //  cardOpen: {
    //    default() {
    //      return false
    //    },
    //  },
    selectedObjects: {
      type: Array,
      default() {
        return []
      },
    },
    mark: null
  },
  methods: {
    calcAxysX: function (pos) {
      return ((pos - startMapX) / deffMapX) * 100 + '%';
      
    },
    calcAxysY: function (pos) {
      return ((pos - startMapY) / deffMapY) * 100 + '%'
    },
    closeCardBtn() {
      this.cardOpen = false
    },
    closeCard: function (event) {
      const marksItems = document.querySelectorAll('.mark')

      marksItems.forEach((markItem) => {
        if (event.target.closest('.mark')) {
          markItem.style.zIndex = null
          event.target.closest('.mark').style.zIndex = 30
          this.cardOpen = false
        }
      })

      // console.log(this.cardOpen)
    },
    showCard: function (data) {
      if (window.innerWidth >= 992) {
        this.cardOpen = true
      }
      
      const marksItems = document.querySelectorAll('.mark')

      marksItems.forEach((markItem) => {
        if (markItem.classList.contains('active')) {
          markItem.classList.remove('active')
        }
      })

      var thisMark = event.target.closest('.mark')

      if (window.innerWidth >= 992) {
        if (thisMark.getBoundingClientRect().top <= 450) {
          thisMark.classList.add('from-top')
          thisMark.classList.add('from-top-origin')
        } else {
          thisMark.classList.contains('from-top') &&
            thisMark.classList.remove('from-top')
          thisMark.classList.contains('from-top-origin') &&
            thisMark.classList.remove('from-top-origin')
        }
      }
/*

      var T = thisMark.getBoundingClientRect().top;
      var B = thisMark.getBoundingClientRect().bottom;
      var L = thisMark.getBoundingClientRect().left;
      var R = thisMark.getBoundingClientRect().right;

      var overlap = false;

      marksItems.forEach((markItem) => {
        if (markItem.classList.contains('active')) {
          markItem.classList.remove('active')
        }

        var OT = markItem.getBoundingClientRect().top;
        var OB = markItem.getBoundingClientRect().bottom;
        var OL = markItem.getBoundingClientRect().left;
        var OR = markItem.getBoundingClientRect().right;

        if (thisMark != markItem)
        if (!((OT > B) || (OB < T) || (OL > R)  || (OR < L))) {
          
          //console.log("OVERLAP " + markItem.title);

          //26x26

          //console.log( (OT-T) + ' ' + (OB-B) + ' ' + (OR - R) + ' ' + (OL - L) );

          var x1 = (T + B)/2;
          var x2 = (OT + OB)/2;
          
          var y1 = (L + R)/2;
          var y2 = (OL + OR)/2;

          //console.log(markItem.style.left);

          console.log(x2-x1);
          console.log(y2-y1);

          //var left = parseFloat(markItem.style.left);
          //markItem.style.left = (left + 1) + "%";

          //console.log(parseFloat(left));


          overlap = true;
          
        }
      })
*/



      // event.target.closest('.mark').getBoundingClientRect().top
      event.target.closest('.mark').style.zIndex = 30
      // event.target.closest('.mark').classList.add('active')

      //if (!overlap) 
      {
      this.$emit('openCardMobile', {
        data: data,
        thisEvent: event,
      });

    }

      // console.log(data)

      // if()
    },
    addCardToSelected: function (data) {
      this.$emit('addCardToSelected', data)
      const marksItems = document.querySelectorAll('.mark')

      marksItems.forEach((markItem) => {
        markItem.style.zIndex = null
      })
      this.cardOpen = false
    },
  },
  
  mounted() {
      var thisMark = document.getElementById("mark_"+this.mark.id);
      var mW = document.getElementsByClassName("map")[0].getBoundingClientRect().width / 100;
      var mH = document.getElementsByClassName("map")[0].getBoundingClientRect().height / 100;
      
      if (!(this.mark.ilguma && this.mark.platuma)) return;
      
      if (addedMarks.length > 0) {
        
        var curTop = ((this.mark.ilguma - startMapY) / deffMapY) * 100;
        var curLeft = ((this.mark.platuma - startMapX) / deffMapX) * 100;
        
        thisMark.style.top =  curTop + '%';
        thisMark.style.left = curLeft + '%';
        thisMark.style.transform = 'scale(' + 1 / this.zoomvalue + ')';
        
        var T = thisMark.getBoundingClientRect().top;
        var B = thisMark.getBoundingClientRect().bottom;
        var L = thisMark.getBoundingClientRect().left;
        var R = thisMark.getBoundingClientRect().right;
        
        var h = (T-B)/2;
        var w = (R-L)/2;
        
        addedMarks.forEach((idas) => {
           var markItem = document.getElementById("mark_"+idas); 
           var OT = markItem.getBoundingClientRect().top;
           var OB = markItem.getBoundingClientRect().bottom;
           var OL = markItem.getBoundingClientRect().left;
           var OR = markItem.getBoundingClientRect().right;
           
           //var OOx = OL + w/2;
           //var OOy = OT + h/2;
     
           var k1 = 1;
           
           
           
          if (!((OT > B) || (OB < T) || (OL > R)  || (OR < L))) {
           
           //overlap
            var vlength = Math.sqrt((OT - T)*(OT - T) + (OL - L)*(OL - L));
            
            if (Math.abs(OL - L) < Math.abs(OT - T)) {
                if (OT == T) {
                    k1 = (1/((OL - L)/vlength))*w;
                    curTop += k1*((OT - T)/vlength) / mH;
                    curLeft += k1*((OL - L)/vlength) / mW;
                    thisMark.style.top =  curTop + '%';
                    thisMark.style.left = curLeft + '%';
                } else {
                    
                    k1 = (1/((OT - T)/vlength))*h;
                    curTop += k1*((OT - T)/vlength) / mH;
                    curLeft += k1*((OL - L)/vlength) / mW;
                    thisMark.style.top =  curTop + '%';
                    thisMark.style.left = curLeft + '%';
                }
                
                
            } else {
                //console.log(this.mark.id + " tempt W" + " " +  L + " " + OL  + " " +  ((OL - L)/vlength));   
                    
                    k1 = (1/((OL - L)/vlength))*w;
                    
                    if (this.mark.id == 233) console.log("čia " + k1 + " " + Math.abs(OL - L) + " " + Math.abs(OT - T) + " " + (Math.abs(OL - L) > Math.abs(OT - T)));
                    
                    curTop += k1*((OT - T)/vlength) / mH;
                    curLeft += k1*((OL - L)/vlength) / mW;
                    thisMark.style.top =  curTop + '%';
                    thisMark.style.left = curLeft + '%';
            }
            
            
            
            thisMark.style.top += h/mH + '%';
            thisMark.style.left += w/mW + '%';
            
          }
        });
          addedMarks.push(this.mark.id);
          
          /*if (addedMarks.count > 140) 
          addedMarks.forEach((idas) => {
              updateSpread(idas);
              //idas;
          });
          */
          
      } else {
        addedMarks.push(this.mark.id);
        document.getElementById("mark_"+this.mark.id).style.top = ((this.mark.ilguma - startMapY) / deffMapY) * 100 + '%';
        document.getElementById("mark_"+this.mark.id).style.left = ((this.mark.platuma - startMapX) / deffMapX) * 100 + '%';
        document.getElementById("mark_"+this.mark.id).style.transform = 'scale(' + 1 / this.zoomvalue + ')';
      }
  },
      
      
      
      
      /*
      console.log(this.zoomvalue)  ;
      
      if (this.zoomvalue != oldZoom) {
          addedMarks = [];
          oldZoom = this.zoomvalue;
          document.getElementById("mark_"+id).style.top = ((y - startMapY) / deffMapY) * 100 + '%';
          document.getElementById("mark_"+id).style.left = ((x - startMapX) / deffMapX) * 100 + '%';
          addedMarks.push({id, x, y});
      } else {
          
          addedMarks.forEach((idas) => {
          idas;
          
          });
          
          console.log(document.getElementById("mark_"+id));
          document.getElementById("mark_"+id).style.top = ((y - startMapY) / deffMapY) * 100 + '%';
          document.getElementById("mark_"+id).style.left = ((x - startMapX) / deffMapX) * 100 + '%';
          
          
          addedMarks.push({id, x, y});
      }      
      
      */
      
  
  directives: {
    ClickOutside,
  },
}
</script>

<style lang="scss" scoped>
.mark {
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
  cursor: pointer;
  &.active {
    z-index: 30;
    .mark-card {
      // display: flex;
      opacity: 1;
      transform: scale(1);
    }
  }
  &.from-top .mark-card {
    bottom: 0;
    top: calc(100% + 5px);
  }
  &.from-top-origin .mark-card {
    transform-origin: left top;
  }
  &:hover {
    z-index: 30;
  }
}
.mark-btn {
  transition-delay: 0.2s;
  animation: zoomInBtn 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  @keyframes zoomInBtn {
    from {
      transform: scale(0);
    }
  }
  &.active-t:not(&.not-active-r),
  &.active-r:not(&.not-active-t) {
    transition-delay: 0s;
  }
  &:hover {
    background: #db3831;
    transition-delay: 0s;
  }
  &.not-hover:not(.active-t):not(.active-r) {
    background: #ced4d1 !important;
    transition-delay: 0s;
  }
  &.not-active-t:not(&.active-t),
  &.not-active-r:not(&.active-r) {
    background: #ced4d1 !important;
    transform: scale(0);
    transition-delay: 0s;
  }
}
@keyframes showCard {
  from {
    opacity: 0;
    transform: scale(0);
  }
}
.mark-card {
  &.v-enter-active {
    animation: showCard 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
  }
  &.v-leave-active {
    opacity: 0.2;
    transform: scale(0) !important;
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  height: fit-content;
  cursor: default;
  // overflow: hidden;
  // height: 158px;
  width: 400px;
  border-radius: 8.59016px;
  background: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: calc(100% + 5px);
  column-gap: 15px;
  // display: none;
  //   opacity: 0.5;
  //   transform: scale(0);
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform-origin: left bottom;
  // @media (max-width: 991.98px) {
  //   position: fixed;
  // }
  &__left {
    width: 99px;
    align-self: stretch;
    flex: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__right {
    flex: 1 1 auto;
    padding: 11px 16px 20px 0px;
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 17px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 6px;
    max-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #212b36;
  }
  &__description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 57px;
    color: #212b36;
  }
  &__button {
    margin-bottom: 0px;
    margin-top: auto;
    display: flex;
    .btn-red {
      margin-right: 6px;
    }
  }
  .btn-red {
    color: #ffffff;
    background: #db3831;
    border-radius: 30px;
    padding: 6px 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
  .btn-border {
    font-weight: 700;
    font-size: 8.05328px;
    line-height: 14px;
    color: #db3831;
    background: #ffffff;
    border: 1px solid #db3831;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 6px 12px;
  }
}
</style>
